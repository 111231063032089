import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Menu, MenuItem, Card, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeFolder } from "../../../redux/actions/FileFolder";
import { makeStyles } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import DotsVerticalIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles(() => {
  return {
    moreIcon: {
      display: "none",
      transition: "all 0.8s ease-in-out",
    },
    folder: {
      "&:hover $moreIcon": {
        display: "flex",
        opacity: "1",
      },
    },
  };
});

function Folders({ folder, onFolderDelete, onFolderEdit, isInModal = false, onModalFolderClick = null }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useSelector((state) => state.User);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onFolderDelete?.(folder?._id);
    handleClose();
  };

  const handleEdit = () => {
    onFolderEdit?.(folder?._id, folder?.name);
    handleClose();
  };

  const handleFolderClick = () => {
    // If in modal, use the modal's click handler
    if (isInModal && onModalFolderClick) {
      onModalFolderClick(folder._id);
    } else {
      // Otherwise use Redux navigation
      dispatch(changeFolder(folder._id));
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <Card
      onDoubleClick={handleFolderClick}
      sx={{
        cursor: "pointer",
        backgroundColor: "transparent",
        boxShadow: 0,
        width: "159px",
        transition: (theme) =>
          theme.transitions.create(["background-color, box-shadow"], {
            easing: theme.transitions.easing.easeInOut,
            duration: 200,
          }),
        "&:hover": {
          backgroundColor: "background.paper",
          boxShadow: 16,
        },
      }}
      variant="outlined">
      {/* Only show menu if not in modal */}
      {!isInModal && (
        <Menu
          id="content-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}>
          {user.isMember ? (
            user.previlliages?.folder?.canEdit ? (
              <MenuItem onClick={handleEdit}>
                <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
                  <EditIcon />
                </SvgIcon>
                {t("views.folders.edit")}
              </MenuItem>
            ) : null
          ) : (
            <MenuItem onClick={handleEdit}>
              <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
                <EditIcon />
              </SvgIcon>
              {t("views.folders.edit")}
            </MenuItem>
          )}

          {user.isMember ? (
            user.previlliages.folder?.canDelete ? (
              <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
                <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
                  <DeleteIcon color="error" />
                </SvgIcon>
                {t("views.folders.delete")}
              </MenuItem>
            ) : null
          ) : (
            <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
              <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
                <DeleteIcon color="error" />
              </SvgIcon>
              {t("views.folders.delete")}
            </MenuItem>
          )}
        </Menu>
      )}

      <Box sx={{ p: 2, position: "relative" }}>
        {!isInModal && (
          <Stack alignItems="center" direction="row" justifyContent="flex-end" sx={{ pt: 2, px: 2, position: "absolute", right: 0, paddingTop: 0 }}>
            <IconButton onClick={handleClick}>
              <SvgIcon fontSize="small">
                <DotsVerticalIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        )}

        <Box sx={{ display: "flex", mb: 1 }} onClick={isMobile ? handleFolderClick : null}>
          <Box sx={{ display: "inline-flex", cursor: "pointer" }}>
            <img src="/assets/icons/icon-folder.svg" alt="folder" />
          </Box>
        </Box>
        <Typography sx={{ cursor: "pointer" }} variant="subtitle2" onClick={isMobile ? handleFolderClick : null}>
          {folder.name.substring(0, 10)}
        </Typography>
      </Box>
    </Card>
  );
}

export default Folders;
