import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import DotsVerticalIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";
import { Checkbox, Box, Divider, SvgIcon, Stack, Menu, MenuItem, IconButton, Typography, Card, FormControlLabel, FormGroup } from "@mui/material";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ImageIcon from "@mui/icons-material/Image";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { useTranslation } from "react-i18next";
import { FaTwitch } from "react-icons/fa";
import "./contentcard.css";

const useStyles = makeStyles((theme) => {
  return {
    cardContainer: {
      background: "#fffff !important",
      display: "flex !important",
      flexDirection: "column !important",
      margin: "0 auto !important",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
    },
    cardUpper: {
      width: { xl: "100%", lg: "100%", sm: "350px", xs: "350px" },
      cursor: "pointer",
      height: "100px",
      marginBottom: "16px",
      width: "200px",
    },
    cardBottom: {
      width: { xl: "100%", lg: "100%", sm: "350px", xs: "350px" },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cardImage: {
      height: "100% !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      marginBottom: "16px",
    },
    cardImg: {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
    cardBottomContent: {
      height: "90%",
      width: "95%",
    },
    cardBottomContentName: {
      margin: "5px 13px",
      height: "30%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: "17px",
      fontWeight: "500",
    },
    cardBottomContentDetail: {
      height: "70%",
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  };
});

function ContentCard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openSplitScreenDialog, setOpenSplitScreenDialog] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type, obj) => {
    if (type === "add_playlist") {
      props.onAddPlaylist(obj);
    }
    if (type === "push_screen") {
      props.onPushScreen(obj);
    }
    if (type === "on_move") {
      props.onMove(obj.id);
    }
    if (type === "on_delete") {
      props.onDelete(obj.id);
    }
    setAnchorEl(null);
  };

  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "mon";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + "sec";
  }

  const onChangeSingleContent = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      props.onCheckedSingleContent(value);
    } else {
      props.onUncheckedSingleContent(value);
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <>
      <Card
        sx={{
          backgroundColor: "transparent",
          boxShadow: 0,
          minWidth: "200px",
          transition: (theme) =>
            theme.transitions.create(["background-color, box-shadow"], {
              easing: theme.transitions.easing.easeInOut,
              duration: 200,
            }),
          "&:hover": {
            backgroundColor: "background.paper",
            boxShadow: 16,
          },
        }}
        variant="outlined">
        <Stack
          className={"card--container"}
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            pt: 2,
            px: 2,
          }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={onChangeSingleContent}
                  checked={props.checked ? true : false}
                  id={`checkbox_${props.contentObject.id}`}
                  value={props.contentObject.id}
                />
              }
              label=""
            />
          </FormGroup>

          <IconButton
            onClick={(e) => handleClick(e)}
            aria-controls={open ? "content-menu" : undefined}
            aria-haspopup={"true"}
            aria-expanded={open ? "true" : undefined}
            id="content-menu-btn">
            <SvgIcon fontSize="small">
              <DotsVerticalIcon />
            </SvgIcon>
          </IconButton>

          <Menu
            id={"content-menu"}
            anchorEl={anchorEl}
            open={open}
            MenuListProps={{
              "aria-labelledby": "content-menu-btn",
            }}
            onClose={handleClose}
            keepMounted
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}>
            <MenuItem onClick={() => handleClose("add_playlist", props.contentObject)}>{t("components.contentCard.addToPlaylist")}</MenuItem>
            {/* <MenuItem onClick={() => handleClose("push_screen", props.contentObject)}>{t("components.contentCard.pushToScreen")}</MenuItem> */}
            <MenuItem onClick={() => handleClose("on_move", props.contentObject)}>{t("components.contentCard.move")}</MenuItem>
            {/* <MenuItem onClick={() => handleClose("on_delete", props.contentObject)}>{t("components.contentCard.delete")}</MenuItem> */}
          </Menu>
        </Stack>
        <Box
          className={`${classes.cardContainer}`}
          sx={{
            maxWidth: "200px",
            height: {
              xl: "250px",
              xs: "200px",
            },
            width: "100%",
          }}>
          {props.contentObject.widget?.widgetCat === "website" ? (
            <a style={{ cursor: "pointer", textDecoration: "none" }} href={props.contentObject.widget.url} target="_blank">
              <Box className={`${classes.cardUpper}`}>
                <Box className={classes.cardImage}>
                  <Box sx={{ position: "absolute", top: 0, left: 0 }}></Box>
                  {props.type === "image" && <img src={props.url} className={classes.cardImg} />}
                  {props.type === "video" && <video src={props.url} className={classes.cardImg}></video>}
                  {props.contentObject.embed === "embed" && <embed src={props.contentObject.url} className={classes.cardImg}></embed>}
                  {props.type === "widget" && <img src={props.contentObject.widget?.widgetIcon} style={{ maxWidth: "80%", maxHeight: "100%" }} />}
                  {props.type === "split_screen" && <img src={props.contentObject.widget?.widgetIcon} style={{ maxWidth: "80%", maxHeight: "100%" }} />}
                </Box>
              </Box>
            </a>
          ) : (
            <Box
              className={`${classes.cardUpper}`}
              onClick={() => {
                if (props.contentObject?.type && props.contentObject?.type === "split_screen") {
                  props.handleSeeSplitScreen(props.contentObject);
                } else {
                  props.onPreview({
                    url: props.contentObject.url,
                    contentType: props.contentObject.type,
                    embed: props.contentObject.embed,
                    widget: props.contentObject.widget,
                  });
                }
              }}>
              <Box className={classes.cardImage}>
                <Box sx={{ position: "absolute", top: 0, left: 0 }}></Box>
                {props.type === "image" && <img src={props.url} className={classes.cardImg} />}
                {props.type === "video" && <video src={props.url} className={classes.cardImg}></video>}
                {props.contentObject.embed === "embed" && <embed src={props.contentObject.url} className={classes.cardImg}></embed>}
                {props.type === "widget" && props.contentObject.widget?.widgetCat === "twitch" && (
                  <img src={"/images/twitch_app.png"} style={{ maxWidth: "80%", maxHeight: "100%" }} />
                )}
                {props.type === "widget" && props.contentObject.widget?.widgetCat !== "twitch" && (
                  <img src={props.contentObject.widget?.widgetIcon} style={{ maxWidth: "80%", maxHeight: "100%" }} />
                )}
                {props.type === "split_screen" && <img src={props.contentObject.widget?.widgetIcon} style={{ maxWidth: "80%", maxHeight: "100%" }} />}
              </Box>
            </Box>
          )}
          <Box className={classes.cardBottom} sx={{ width: "100%" }}>
            <Box
              sx={{
                p: 2,
                width: "100%",
              }}>
              <Typography sx={{ cursor: "pointer" }} variant="subtitle2">
                {props.name.substring(0, 15)}
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={1}>
                <div>
                  <Box
                    sx={{
                      height: "4vh",
                      width: "2vw",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    {props.type === "image" && <ImageIcon color="primary" />}
                    {props.type === "video" && <SlideshowIcon color="primary" />}
                    {props.type === "widget" && <AppRegistrationIcon color="primary" />}
                  </Box>
                </div>
                <Typography color="text.secondary" variant="caption">
                  {`${t("components.contentCard.added")} ${timeSince(new Date(props.createdAt).getTime())} ${t("components.contentCard.ago")}`}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default ContentCard;
