import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { loginFlagOff, passwordChangeEmail } from "../../redux/actions/User";
import { useTranslation } from "react-i18next";

function ForgetPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { isEmailSent, isEmailNotSent } = useSelector((state) => state.User);

  useEffect(() => {
    if (isEmailSent) {
      toast.success(t("views.forgetPassword.emailSentSuccess", { email }));
      dispatch(loginFlagOff());
    }
    if (isEmailNotSent) {
      toast.error(t("views.forgetPassword.emailNotValid", { email }));
      dispatch(loginFlagOff());
    }
  }, [isEmailSent, isEmailNotSent]);

  const [canResend, setCanResend] = useState(true);

  const passwordChange = useCallback(() => {
    setCanResend(false);
    dispatch(passwordChangeEmail(email));

    setTimeout(() => setCanResend(true), 10000);
  }, [email]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100vw",
          position: "relative",
        }}>
        <Box
          sx={{
            height: "111.45px",
            width: "158.47px",
            position: "absolute",
            top: "30px",
          }}>
          <img src="/images/VCPLogo.png" style={{ height: "100%", width: "100%", objectFit: "cover" }} alt="" />
        </Box>
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            background: "url(/images/loginside.png) ,rgba(255,255,255,.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}>
          <Box
            sx={{
              height: "80vh",
              width: {
                xs: "100%",
                sm: "100%",
                md: "30vw",
                lg: "30vw",
                xl: "30vw",
              },
            }}>
            <Typography variant={"body1"} sx={{ margin: "30px auto", textAlign: "center" }}>
              <Typography variant={"caption"} sx={{ color: "#016AFE", fontWeight: "700", fontSize: "40px" }}>
                VCPlayer
              </Typography>
              <br />
              <Typography variant={"caption"} sx={{ color: "#313031", fontWeight: "700", fontSize: "40px" }}>
                SIGNAGE
              </Typography>
            </Typography>

            <Box
              sx={{
                height: "60vh",
                width: "90%",
                margin: "55px auto",
              }}>
              <Box
                sx={{
                  height: "50px",
                  width: "90%",
                  margin: "20px auto",
                }}>
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant={"standard"}
                  label={t("views.forgetPassword.email")}
                  type={"email"}
                  sx={{ height: "70px", input: { color: "#313031" } }}
                  fullWidth
                />
              </Box>

              <Box
                sx={{
                  height: "50px",
                  width: "90%",
                  margin: "10px auto",
                }}>
                <Button
                  onClick={passwordChange}
                  sx={{
                    textTransform: "capitalize",
                    height: { xl: "54px", lg: "50px", md: "45px", sm: "40px", xs: "30px" },
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "38px",
                    backgroundColor: "#016AFE",
                    borderRadius: "6px",
                  }}
                  variant={"contained"}
                  fullWidth
                  disabled={!canResend}>
                  {t("views.forgetPassword.send")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            display: {
              sm: "none",
              xs: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            background: "url(/images/logincarouselside.png) ,linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%);",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              height: "90vh",
              width: "90%",
              // border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant={"h4"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "700",
                  fontSize: { xl: "40px", lg: "30px", md: "20" },
                  lineHeight: "102.6%",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "uppercase",
                }}>
                {t("views.forgetPassword.screenSlogan1")}
              </Typography>
              <Typography
                variant={"h2"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "700",
                  fontSize: { xl: "60px", lg: "40px", md: "20" },
                  lineHeight: "102.6%",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "uppercase",
                }}>
                {t("views.forgetPassword.screenSlogan2")}
              </Typography>
              <Typography
                variant={"caption"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "400",
                  fontSize: { xl: "22px", lg: "15px", md: "10" },
                  lineHeight: "32px",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "capitalize",
                  width: "500px",
                  margin: { xl: "25px 0", lg: "15px 0", md: "10px 0" },
                }}>
                {t("views.forgetPassword.sloganDescription1")}
              </Typography>
            </Box>

            <Box
              sx={{
                height: { xl: "300px", lg: "300px", md: "300px" },
                width: { xl: "350px", lg: "300px", md: "300px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={"/images/iMacMonitor.png"} style={{ height: "100%", width: "100%" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                variant={"caption"}
                sx={{
                  color: "#F0F3FB",
                  fontWeight: "400",
                  fontSize: { xl: "15px", lg: "13px", md: "10px" },
                  lineHeight: "22px",
                  textAlign: "center",
                  letterSpacing: "-0.02em",
                  textTransform: "capitalize",
                  width: "500px",
                  margin: { xl: "25px 0", lg: "15px 0", md: "10px 0" },
                }}>
                {t("views.forgetPassword.sloganDescription2")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ForgetPassword;
